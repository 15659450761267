<div class="container">
  <div class="topSection">
    <div class="row">
      <!-- <div class="d-flex justify-content-start"> -->
      <form
        [formGroup]="pgmScriptForm"
        class="col-12 col-md-12 col-lg-8 col-xl-6 col-sm-12"
        (ngSubmit)="getProgramDetails()"
      >
        <mat-form-field class="col-7 example-full-width h-20">
          <mat-label>Programs List</mat-label>
          <input
            matInput
            formControlName="programGroup"
            required
            [matAutocomplete]="autoGroup"
          />
          <mat-autocomplete #autoGroup="matAutocomplete">
            <mat-optgroup *ngFor="let group of programGroupOptions | async">
              {{ group.letter }}
              <mat-option *ngFor="let name of group.programName" [value]="name">
                {{ name }}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
        </mat-form-field>
        <button
          class="col-5"
          type="submit"
          [ngClass]="{
            submitButton: !pgmScriptForm.invalid,
            submitButtonDisabled: pgmScriptForm.invalid
          }"
          [disabled]="pgmScriptForm.invalid"
        >
          Generate Tree
        </button>
      </form>
      <!-- </div> -->
      <div
        class="col-12 col-md-12 col-lg-4 col-xl-6 col-sm-12 mt-5 mt-lg-0 mt-xl-0"
      >
        <button
          *ngIf="gotScripts"
          mat-mini-fab
          aria-label="Example icon button with a home icon"
          class="download"
          (click)="downloadScripGen()"
        >
          <mat-icon>download</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
<!--
  Div to Display the Tree Data
-->
<div class="pgmcontainer">
  <svg #showPgmVsScript id="showPgmVsScript"></svg>
</div>

<!-- <ng-template #notLoggedIn>
  <h1>Please log in to continue.</h1>
</ng-template>


<ng-container *ngIf="!gotScripts">
  <ng-container *ngTemplateOutlet="notLoggedIn"></ng-container>
</ng-container> -->
