import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { SharedModule } from "./shared.module";
import { ShareDataService } from "./share-data.service";
import { AppComponent } from "./../app.component";
import { SocketioClientServices } from "./socketio-client.services";
import { HttpAdapterService } from "./http-adapter.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "src/environments/environment";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class AuthguardGuard {
  public sharedData = {};
  parentRoutes = ["scripts", "report", "libraryManagement"];
  constructor(
    private _router: Router,
    private sharedServices: ShareDataService,
    private _snackBar: MatSnackBar,
    private userService: UserService,
    private httpAdapter: HttpAdapterService,
    private socket: SocketioClientServices
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = this.userService.getToken();
    if (token) {
      const payload = this.userService.getPayload();
      const urlSegments = state.url.split("/");
      const selectedSystem = urlSegments[1];
      const parentRoute = urlSegments[2];
      console.log("Auth Guard Invoked**", state.url);

      if (this.parentRoutes.includes(parentRoute)) {
        console.log("Auth Guard parentRoutes**", parentRoute);

        if (parentRoute == "scripts") {
          this.sharedData = {};
          if (state.url.indexOf("runScripts") > 0) {
            this.sharedData["runValidation"] = false;
          } else if (state.url.indexOf("validate") > 0) {
            this.sharedData["runValidation"] = true;
          }
          this.sharedData["route"] = "/scripts";
          this.sharedData["view"] = "Live";
          this.sharedData["freezeId"] = "live";
        } else if (parentRoute == "report") {
          console.log("Invoked");
          this.sharedData = {};
          this.sharedData["route"] = "/report";
          this.sharedData["view"] = "Report";
        } else if (parentRoute == "libraryManagement") {
          console.log("libraryManagement");
          this.sharedData = {};
          this.sharedData["view"] = "Library";
          this.sharedData["route"] = "/libraryManagement";
        }
      }

      /**
       * The following function checks the status of the socket connection. (PM-147)
       */
      console.log(
        "Socket Connection ***",
        this.socket.connectionStatus(),
        state.url
      );
      if (!this.socket.connectionStatus()) {
        /**
         * The following function initiates the socket connection. (PM-147)
         */
        const socketData = this.socket.socketConnection(payload);
        socketData.then((data) => {
          console.log(
            "Socket Connection Promise ***",
            data,
            this.socket.connectionStatus()
          );
        });
        /**
         * The parameters listed below are all used for sharing socket information. - (PM-71)
         * The socketConnection function will return information about the socket connection. - (PM-71)
         */
        if (socketData["__zone_symbol__state"]) {
          this.sharedData["socket"] = socketData["__zone_symbol__value"];
        }
      } else {
        console.log("Expected");
        /**
         * The following code will manually update the socket connection details,Because the socket is already connected.  (PM-147)
         */
        // this.sharedData["socket"] = {
        //   auth: {
        //     userName: localStorage.getItem("userName"),
        //     groupName: localStorage.getItem("groupName"),
        //   },
        //   connection: true,
        // };
      }
      /**
       * The parameters listed below are all used for sharing socket information.- (PM-71)
       */
      this.sharedData["socket"]["moveToGivenRunScript"] = null;
      this.sharedData["socket"]["NewLogAdded"] = false;
      this.sharedData["socket"]["SocketCheckInfo"] = false;
      this.sharedData["socket"]["SocketlogInfo"] = {
        execution: false,
        logStatus: false,
      };

      if (environment.systems.includes(selectedSystem)) {
        this.sharedData["selectedSystem"] = selectedSystem;
        this.fetchSystems(selectedSystem).then((options) => {
          this.sharedData["vpars"] = options;
          console.log('VPARS Changed',this.sharedData)
          this.sharedServices.changeData(this.sharedData);
          return true;
        }).catch((error) => {
          console.error("Error fetching systems", error);
          this._router.navigateByUrl("/welcome");
          new AppComponent(this._snackBar).showSnackBar("Error in Fetching Systems", "error");
          return false;
        });
        return true;
      } else if (selectedSystem == "welcome" && urlSegments.length == 2) {
        return true;
      } else {
        this.sharedData = {};

        this._router.navigateByUrl("/welcome");
        new AppComponent(this._snackBar).showSnackBar("Invalid URL", "error");
        return true;
      }
    } else {
      this._router.navigateByUrl("/login");
      //new AppComponent(this._snackBar).showSnackBar("Please login", "error");
    }
  }

  fetchSystems(selectedSystem: string) {
    const params = {
      url: `vpars/fetchSystems`,
      system: selectedSystem,
    };

    return new Promise((resolve, reject) => {
      this.httpAdapter.httpGetNew(params).subscribe(
        (res) => {
          const options = res.body || [];
          resolve(options);
        },
        (error) => {
          console.error("Error:", error);
          alert("An error occurred while fetching systems");
          reject(error);
        }
      );
    });
  }
}
