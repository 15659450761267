<div
  *ngIf="!logs?.length"
  class="messageOuterContainer d-flex justify-content-center"
>
  <div class="messageContainer">No report found</div>
</div>

<div
  *ngIf="logs?.length"
  class="d-flex flex-column"
  style="overflow: auto; height: 90vh"
>
  <div class="topSection m-0" style="display: flex; align-items: center">
    <button
      class="m-2"
      mat-raised-button
      color="primary"
      (click)="toggleSelectAll()"
    >
      {{ allSelected ? "Uncheck All" : "Check All" }}
    </button>
    <p class="m-0" *ngIf="logs?.length">
      &nbsp;&nbsp;&nbsp;&nbsp;{{ getSelectedCount() }} Selected
    </p>
  </div>
  <div class="middleSection">
    <mat-grid-list cols="4" rowHeight="100px">
      <mat-grid-tile class="logsSection" *ngFor="let log of logs">
        <section class="checkboxLog">
          <input type="checkbox" [(ngModel)]="log.checked" />
        </section>
        <div class="card">
          <div class="container">
            <div class="text-center logName textContent">
              <h4>
                <b>{{ log._id }}</b>
              </h4>
            </div>
            <div class="d-flex">
              <div class="text-left mr-auto p-2 textContent">
                Start Time:
                <span class="dateClass">{{
                  log.startTime | date : "MMM d, hh:mm"
                }}</span>
              </div>
              <div class="p-2 textContent">
                End Time:
                <span class="dateClass">{{
                  log.endTime | date : "MMM d, hh:mm"
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <div *ngIf="logs.length" class="mt-2 bottomSection">
    <form [formGroup]="reportForm" (ngSubmit)="downloadReports()">
      <mat-form-field>
        <mat-select
          formControlName="selectedReportControl"
          placeholder="Select Report Type"
        >
          <mat-option
            *ngFor="let report of reports"
            [value]="report.controller"
          >
            {{ report.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button
        type="submit"
        [ngClass]="buttonClasses"
        [disabled]="isButtonDisabled"
      >
        Download
      </button>
    </form>
  </div>
</div>
