<div class="rundetails" *ngIf="configPresent">
  <div class="buttons-container">
    <mat-button-toggle-group
      name="fontStyle"
      aria-label="Font Style"
      class="fixedButton"
    >
      <button
        mat-icon-button
        [disabled]="CurrentConfForm?.invalid"
        class="confSubmit"
        aria-label="Validate & Save"
        *ngIf="scriptDeatils['scriptRunStatus'] != 'inProgress'"
        (click)="validateSaveAndRunConfig(false)"
      >
        <mat-icon fontIcon="done">
          <span class="text">Validate & Save</span></mat-icon
        >
      </button>

      <button
        class="container runSubmit ml-1"
        aria-label="run"
        *ngIf="scriptDeatils['scriptRunStatus'] != 'inProgress'"
        (click)="validateSaveAndRunConfig(true)"
      >
        <span>RUN</span>
      </button>
      <button
        class="container abortButton"
        *ngIf="scriptDeatils['scriptRunStatus'] === 'inProgress'"
        (click)="abortScript()"
      >
        <span>Abort</span>
      </button>
    </mat-button-toggle-group>
  </div>

  <div class="row confDetails">
    <div class="col-12">
      <div class="d-flex justify-content-end">
        <button
          class="deleteButton"
          data-toggle="tooltip"
          title="Delete Config"
          [disabled]="scriptDeatils['scriptRunStatus'] === 'inProgress'"
          (click)="deleteScript()"
        >
          <mat-icon
            aria-hidden="false"
            class="delete-icon"
            fontIcon="delete"
          ></mat-icon>
        </button>
      </div>
      <form
        *ngIf="CurrentConfForm"
        [formGroup]="CurrentConfForm"
        style="height: 100vh"
      >
        <div
          id="parentDiv"
          *ngIf="CurrentConfForm != null"
          class="pb-5"
          style="height: 90vh; overflow-y: scroll"
        >
          <div>
            <div
              class="container-fluid d-flex justify-content-center mt-2"
              *ngIf="
                !confChangeStatus.status &&
                confChangeStatus.newKeys.length > 0 &&
                confChangeStatus.newKeys[0]['deleted']
              "
            >
              <div class="alert alert-danger col-9" role="alert">
                <div class="row d-flex justify-content-center">
                  <b>Notes:</b>&nbsp; Few field's Removed from Config
                  <br />
                </div>
                <!-- <div class="row d-flex justify-content-center">
                                    {{confChangeStatus.newKeys[0]['deleted'] | json}}
                                </div> -->
              </div>
            </div>
            <div class="mt-2" id="head"></div>
            <div
              class="container-fluid d-flex justify-content-center"
              *ngIf="confChangeStatus.status"
            >
              <div class="alert alert-info col-9 mt-2" role="alert">
                <div class="row d-flex justify-content-center">
                  <span style="font-size: 23px"
                    >Configration has been updated with new keys</span
                  >
                </div>
                <div class="row d-flex justify-content-center">
                  <button
                    mat-stroked-button
                    color="primary"
                    class="m-2 buttonclass"
                    (click)="updateConfig(scriptDeatils)"
                  >
                    Update
                  </button>
                  <button
                    mat-stroked-button
                    color="primary"
                    class="m-2 buttonclass"
                  >
                    Cancel
                  </button>
                </div>
                <p
                  class="row d-flex justify-content-center m-0 errormessage"
                  [ngClass]="{ blink: errormessage }"
                >
                  * Need to update the configration to process
                </p>
              </div>
            </div>
          </div>

          <!-- {{CurrentConfForm.value|json}} -->
          <div id="General" class="ConfFields">
            <h5 style="margin: 0">GENERAL</h5>
            <app-general
              [(GeneralForm)]="CurrentConfForm.controls.GENERAL"
              [runId]="CurrentConfForm.controls.SCRIPT.get('MSG_FILES')"
              [newKeysAdded]="this.GENERALNewKeys"
            ></app-general>
          </div>
          <div id="Scripts" class="ConfFields">
            <h5>SCRIPTS</h5>
            <app-scripts
              [(conf)]="CurrentConfForm.controls.SCRIPT"
              [newKeysAdded]="this.SCRIPTNewKeys"
            >
            </app-scripts>
          </div>
          <div id="Phases" class="ConfFields">
            <h5>PHASES</h5>
            <app-phases
              [(PhasesForm)]="CurrentConfForm.controls.PHASES"
              [newKeysAdded]="this.PHASESNewKeys"
            ></app-phases>
          </div>
          <div id="TraceDef" class="ConfFields">
            <h5>TRACE DEF</h5>
            <app-tracedef
              [(conf)]="CurrentConfForm.controls.TRACEDEF"
              [newKeysAdded]="this.TRACEDEFNewKeys"
            ></app-tracedef>
          </div>
          <div id="TraceOvr" class="ConfFields">
            <h5>TRACE OVERRIDE</h5>
            <app-traceoverride
              [(conf)]="CurrentConfForm.controls.TRACE_OVERRIDE"
              [newKeysAdded]="this.TRACE_OVERRIDENewKeys"
            ></app-traceoverride>
          </div>
          <div id="Load" class="ConfFields" *ngIf="CurrentConfForm.controls.GENERAL.get('DOLOAD').value">
            <h5>Load</h5>
            <app-load
              [(conf)]="CurrentConfForm.controls.LOAD"
              [newKeysAdded]="this.LOADNewKeys"
            ></app-load>
          </div>
        </div>
      </form>
      <br />
    </div>
  </div>
</div>
