import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { startWith, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { HttpAdapterService } from "../_services/http-adapter.service";
import { AppComponent } from "../app.component";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { ShareDataService } from "../_services/share-data.service";
import { SocketioClientServices } from "../_services/socketio-client.services";
import * as d3 from "d3";
import { hierarchy, tree } from "d3-hierarchy";
import * as $ from "jquery";
import { DialogueComponent } from "../errorPages/dialogue/dialogue.component";
import { saveAs } from "file-saver";
import { animate, style, transition, trigger } from "@angular/animations";
import { MatDialog } from "@angular/material/dialog";

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

@Component({
  selector: "app-pgm-script",
  templateUrl: "./pgm-script.component.html",
  styleUrls: ["./pgm-script.component.scss"],
  animations: [
    trigger("smoothScroll", [
      transition(":enter", [
        style({ opacity: 0, transform: "translateY(-100%)" }),
        animate("500ms", style({ opacity: 1, transform: "translateY(0)" })),
      ]),
    ]),
  ],
})
export class PgmScriptComponent implements OnInit {
  pgmScriptForm: FormGroup;

  public httpAdapter: HttpAdapterService;
  public sharedData = {};
  programGroups: any[];
  programGroupOptions: Observable<any[]>;
  subscription: any;
  gotScripts: boolean;
  treeData: any;

  constructor(
    private http: HttpClient,
    private sharedServices: ShareDataService,
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private socket: SocketioClientServices,
    public dialog: MatDialog,
    private el: ElementRef
  ) {
    this.httpAdapter = new HttpAdapterService(this.http);
    this.pgmScriptForm = this._formBuilder.group({
      programGroup: "",
    });
  }
  scrollToTree(): void {
    const treeContainer =
      this.el.nativeElement.querySelector("#tree-container");
    if (treeContainer) {
      treeContainer.scrollIntoView({ behavior: "smooth" });
    }
  }
  ngOnInit() {
    var self = this;
    self.getProgramNames();
    // self.subscription = self.sharedServices.currentData$.subscribe(data => {
    //   self.sharedData = { ...data };
    //   console.log(self.sharedData)
    // })
  }

  private _filterGroup(value: string): any[] {
    if (value) {
      return this.programGroups
        .map((group) => ({
          letter: group.letter,
          programName: _filter(group.programName, value),
        }))
        .filter((group) => group.programName.length > 0);
    }

    return this.programGroups;
  }

  getProgramNames() {
    var self = this;
    var params = {
      url: `pgmvsscript/getPrograms`,
    };
    self.httpAdapter.httpGet(params, function (err, response: any) {
      if (!err) {
        self.programGroups = response.body.data;
        self.programGroupOptions = self.pgmScriptForm
          .get("programGroup")!
          .valueChanges.pipe(
            startWith(""),
            map((value) => self._filterGroup(value || ""))
          );
      }
    });
  }
  scripts = {};
  downloadScripGen() {
    var self = this;
    var params = {
      url: "scriptGen/generateScript",
      data: {
        generateScript: self.scripts
      },
    };
    self.httpAdapter.httpPost(params, function (err, result) {
      if (err) {
        console.log("Error", err);
      } else {
        if (result.status == 200) {
          console.log("Download scripts 👉️ \r", result.body.message);
          const today = new Date();
          const blob = new Blob([result.body.message], { type: "text" });
          saveAs(
            blob,
            self.pgmScriptForm.value.programGroup +
              "_" +
              today.getDate().toString() +
              "-" +
              today.toLocaleString("default", { month: "long" }) +
              "-" +
              today.getFullYear().toString() +
              "-" +
              today.getHours().toString() +
              "HH-" +
              today.getMinutes().toString() +
              "MM.input"
          );
        } else {
          console.log("Error Message 👉️ \r", result.body.message);
          self.dialog.open(DialogueComponent, {
            direction: "ltr",
            minWidth: "350px",
            position: { top: "10px" },
            data: { Title: "Error Message", Content: result.body.message },
          });
        }
      }
    });
  }

  getProgramDetails() {
    var self = this;
    self.scripts = {};
    self.gotScripts = false;
    console.log(self.pgmScriptForm.value.programGroup);
    let groupName = localStorage.getItem("groupName");
    const params = {
      url: `pgmvsscript/getscriptsForProgram?pgmName=${self.pgmScriptForm.value.programGroup}&groupName=${groupName}`,
    };
    self.httpAdapter.httpGet(params, function (err, result) {
      if (!err) {
        self.treeData = result.body.data;
        self.treeData["children"].map((script) => {
          script["children"].map((subscript) => {
            var list = self.scripts[script["name"]];
            if (list) {
              if (!list.includes(subscript["name"])) {
                // ✅ only runs if value not in array
                list.push(subscript["name"]);
              }
            } else {
              self.scripts[script["name"]] = [subscript["name"]];
            }
          });
        });
        self.gotScripts = true;
        self.generateTree();
        //self.sharedServices.changeData(self.sharedData)
        //self.router.navigate(['generateTree'], { relativeTo: self.route });
        //self.pgmScriptForm.reset()
      } else {
        console.log("err", err);
      }
    });
  }

  generateTree() {
    d3.select("#showPgmVsScript").text("");
    // Specify the charts’ dimensions. The height is variable, depending on the layout.
    const marginTop = 10;
    const marginBottom = 10;
    const screenWidth = window.innerWidth; // Get the screen width
    const screenHeight = window.innerHeight; // Get the screen height

    // Rows are separated by dx pixels, columns by dy pixels. These names can be counter-intuitive
    // (dx is a height, and dy a width). This because the tree must be viewed with the root at the
    // “bottom”, in the data domain. The width of a column is based on the tree’s height.
    const root = d3.hierarchy(this.treeData);
    const dx = 300;
    const dy = 150;

    // Define the tree layout and the shape for links.
    const tree = d3
      .tree()
      .nodeSize([dx, dy])
      .separation((a, b) => 1);
    const diagonal = d3
      .linkVertical() // Use linkVertical for vertical tree layout
      .x((d) => d.x + 100 / 2) // Start the line from the middle of the source rectangle
      .y((d) => d.y + 80 / 2);

    // Create the SVG container, a layer for the links and a layer for the nodes.
    const svg = d3
      .select("#showPgmVsScript")
      .attr("width", "100%")
      .attr("height", "80vh")
      .attr("viewBox", [-marginTop, -100, screenWidth / 2, 700]);

    // .attr("style", "border: 5px solid red");

    const gLink = svg
      .append("g")
      .attr("fill", "none")
      .attr("stroke", "#555")
      .attr("stroke-opacity", 0.4)
      .attr("style", "over-flow: visible")
      .attr("stroke-width", 1.5);

    const gNode = svg
      .append("g")
      .attr("cursor", "pointer")
      .attr("width", "100%")
      .attr("style", "over-flow: visible")
      .attr("pointer-events", "all");

    const zoom = d3
      .zoom()
      .scaleExtent([0.1, 5]) // Define the zoom scale range
      .on("zoom", zoomed);

    svg.call(zoom);

    function zoomed(event) {
      // Adjust the SVG container's transformation based on the zoom behavior
      gNode.attr("transform", event.transform);
      gLink.attr("transform", event.transform);
    }

    function update(event, source) {
      const duration = event?.altKey ? 3500 : 650; // hold the alt key to slow down the transition
      const nodes = root.descendants().reverse();
      const links = root.links();

      // Compute the new tree layout.
      tree(root);

      let left = root;
      let right = root;
      root.eachBefore((node) => {
        if (node.x < left.x) left = node;
        if (node.x > right.x) right = node;
      });

      const height = right.x - left.x + marginTop + marginBottom;

      const transition = svg
        .transition()
        .duration(duration)
        .attr("height", "85vh")
        .attr("viewBox", [-screenWidth / 2.5, -50, screenWidth, screenHeight])
        .tween(
          "resize",
          window.ResizeObserver ? null : () => () => svg.dispatch("toggle")
        );

      // Update the nodes…
      const node = gNode.selectAll("g").data(nodes, (d) => d.id);

      // Enter any new nodes at the parent's previous position.
      // const nodeEnter = node
      //   .enter()
      //   .append("g")
      //   .attr("transform", (d) => `translate(${source.y0},${source.x0})`)
      //   .attr("fill-opacity", 0)
      //   .attr("stroke-opacity", 0)
      //   .on("click", (event, d) => {
      //     d.children = d.children ? null : d._children;
      //     update(event, d);
      //   });
      const nodeEnter = node
        .enter()
        .append("g")
        .attr("transform", (d) => `translate(${source.y},${d.x})`)
        .attr("fill-opacity", 0)
        .attr("stroke-opacity", 0)
        .on("click", (event, d) => {
          d.children = d.children ? null : d._children;
          update(event, d);
        });
      nodeEnter
        .append("rect")
        .attr("width", (d) => (d.data.name.split("\n").length > 2 ? 250 : 100)) // Set the width of the rectangle
        .attr("height", (d) =>
          d.data.name.split("\n").length > 3
            ? 250
            : d.data.name.split("\n").length > 1
            ? 100
            : 50
        ) // Set the height of the rectangle
        .attr("fill", "white")
        .attr("stroke-width", 1) // Set the stroke width (if needed)
        .attr("stroke", "black"); // Set the stroke color (if needed)

      nodeEnter
        .append("foreignObject")
        .attr("x", 0) // Adjust the x position as needed
        .attr("y", 13) // Adjust the y position as needed
        .attr("width", (d) => (d.data.name.split("\n").length > 2 ? 250 : 100)) // Set the width of the foreignObject
        .attr("height", (d) =>
          d.data.name.split("\n").length > 3
            ? 250
            : d.data.name.split("\n").length > 1
            ? 100
            : 50
        ) // Set the height of the foreignObject
        .append("xhtml:div") // Use a <div> element to contain HTML content
        .attr("style", "over-flow: visible")
        .html(
          (d) =>
            `<span style=" display: flex; justify-content:center; align-items-center;">${d.data.name}</span>`
        );

      // Clone for the stroke (if needed)
      nodeEnter
        .select("foreignObject")
        .clone(true)
        .lower()
        .attr("stroke-linejoin", "round")
        .attr("stroke-width", 3)
        .attr("stroke", "white");

      // Transition nodes to their new position.
      const nodeUpdate = node
        .merge(nodeEnter)
        .transition(transition)
        .attr("transform", (d) => `translate(${d.x},${d.y})`) // Swap x and y
        .attr("fill-opacity", 1)
        .attr("stroke-opacity", 1);

      // Transition exiting nodes to the parent's new position.
      const nodeExit = node
        .exit()
        .transition(transition)
        .remove()
        .attr("transform", (d) => `translate(${source.y},${source.x})`)
        .attr("fill-opacity", 0)
        .attr("stroke-opacity", 0);

      // Update the links…
      const link = gLink.selectAll("path").data(links, (d) => d.target.id);

      // Enter any new links at the parent's previous position.
      const linkEnter = link
        .enter()
        .append("path")
        .attr("d", (d) => {
          const o = { x: source.x0, y: source.y0 };
          return diagonal({ source: o, target: o });
        });

      // Transition links to their new position.
      link.merge(linkEnter).transition(transition).attr("d", diagonal);

      // Transition exiting nodes to the parent's new position.
      link
        .exit()
        .transition(transition)
        .remove()
        .attr("d", (d) => {
          const o = { x: source.x, y: source.y };
          return diagonal({ source: o, target: o });
        });

      // Stash the old positions for transition.
      root.eachBefore((d) => {
        d.x0 = d.x;
        d.y0 = d.y;
      });
    }

    // Do the first update to the initial configuration of the tree — where a number of nodes
    // are open (arbitrarily selected as the root, plus nodes with 7 letters).
    root.x0 = dy / 2;
    root.y0 = 0;
    root.descendants().forEach((d, i) => {
      d.id = i;
      d._children = d.children;
      if (d.depth && d.data.name.length !== 7) d.children = null;
    });

    update(null, root);
  }
}
