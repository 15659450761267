import { Component, OnInit, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { filter } from "rxjs";
import { UserService } from "../_services/user.service";
import { ShareDataService } from "../_services/share-data.service";
import { SocketioClientServices } from "../_services/socketio-client.services";
import { HttpAdapterService } from "../_services/http-adapter.service";
import { environment } from "src/environments/environment";
import { DialogueComponent } from "../errorPages/dialogue/dialogue.component";
import { AppComponent } from "./../app.component";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-nav-layout",
  templateUrl: "./nav-layout.component.html",
  styleUrls: ["./nav-layout.component.scss"],
})
export class NavLayoutComponent implements OnInit, OnDestroy {
  public sharedData: any = {};
  public userName: string;
  public clientSystem: string = environment.clientSystem;
  public selectedSystem: string = "";
  public previousSystem: string = "";
  public userMenu = [{ title: "Welcome", path: "/welcome" }];
  public menuItems = this.userMenu;
  public systems: any[] = environment.systems;
  currentRouteUrl: string = "";
  private routeSubscription: any;
  private socketSubscriptionForWelcome: any;
  Sharedsubscription: any;
  currentRoute: string;

  constructor(
    private router: Router,
    private sharedServices: ShareDataService,
    private socket: SocketioClientServices,
    private dialog: MatDialog,
    private userService: UserService,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private httpAdapter: HttpAdapterService
  ) { }

  ngOnInit() {
    this.setupRouteListener();
    this.setupSocketListener();
    this.socketListener();
    this.setupLocalStorageListener();
    this.onRefresh();
  }

  socketListener() {
    this.Sharedsubscription = this.sharedServices.currentData$.subscribe(
      (data) => {
        this.sharedData = data;
        if (
          this.sharedData["selectedSystem"] &&
          this.sharedData["selectedSystem"] != this.selectedSystem
        )
          this.selectedSystem = this.sharedData["selectedSystem"];
      }
    );
  }

  onRefresh() {
    const segments = this.router.url.split("/");
    const system = segments[1];
    if (
      (this.selectedSystem! = system) &&
      environment.systems.includes(system)
    ) {
      this.selectedSystem = system;
      this.updateMenuItems();
    } else if (system == "welcome") this.selectedSystem = "";
  }

  private setupRouteListener() {
    this.routeSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.currentRouteUrl = this.router.url;
        const segments = this.currentRouteUrl.split("/");
        console.log(segments);

        const system = segments[1];
        if (this.currentRouteUrl == "/welcome") {
          this.menuItems = [{ title: "Welcome", path: "/welcome" }];
        } else if (
          this.selectedSystem != system &&
          environment.systems.includes(system)
        ) {
          this.selectedSystem = system;
          this.handleNext(
            `You are Navigating to ** ${this.selectedSystem}`,
            true,
            true,
            this.currentRouteUrl
          );
        }
      });
  }

  private setupSocketListener() {
    this.socketSubscriptionForWelcome = this.socket
      .listeningFunction("welcome")
      .subscribe((data) => {
        console.log("Data from socket", data);
      });
  }

  private updateMenuItems() {
    // this.menuItems1 = [
    //   { title: "Live", path: `${this.selectedSystem}/scripts` },
    //   // { title: "Reports", path: `${this.selectedSystem}/report` },
    //   {
    //     title: "Library",
    //     path: `${this.selectedSystem}/libraryManagement`,
    //   },
    // ];
    const token = this.userService.getPayload();
    this.menuItems = token["modules"].map((title) => {
      const path = this.mapToPath(title);
      return { title, path };
    });
  }

  private mapToPath(title: string): string {
    if (title === "Live") {
      return `${this.selectedSystem}/scripts`;
    } else if (title === "Library") {
      return `${this.selectedSystem}/libraryManagement`;
    } else if (title === "Pre Load") {
      return `${this.selectedSystem}/preLoad`;
    } else if (title === "API Lite") {
      return `${this.selectedSystem}/simulation`;
    } else if (title === "Reports") {
      return `${this.selectedSystem}/reports`;
    } else if (title === "Regression Logs") {
      return `${this.selectedSystem}/regressionLogs`;
    } else if (title === "S2C Logs") {
      return `${this.selectedSystem}/regressionLogs`;
    }
  }

  private setupLocalStorageListener() {
    // window.addEventListener("storage", (event) => {
    //   if (event.storageArea == localStorage) {
    //     this.router.navigate(["/login"]);
    //   }
    // });
  }

  private handleNext(
    content,
    afterPopUp,
    notifyService,
    currentRouteUrl
  ): void {
    const dialogRef = this.dialog.open(DialogueComponent, {
      direction: "ltr",
      minWidth: "350px",
      position: { top: "10px" },
      panelClass: "my-alert-message-class",
      data: {
        Title: "Information",
        Content: content,
        isConfirm: true,
        isClose: false,
      },
    });

    if (afterPopUp) {
      dialogRef
        .afterClosed()
        .pipe()
        .subscribe((result) => {
          this.updateMenuItems();
          const urlArray = this.router.url.split("/");
          this.currentRoute = this.menuItems[0]["path"];

          if (urlArray.length == 5 || urlArray[1] == "welcome") {
            this.router.navigate([this.currentRoute]);
          } else if (urlArray.length == 3) {
            this.router.navigate([this.selectedSystem + `/${urlArray[2]}`]);
          } else {
            this.router.navigate([
              this.selectedSystem + `/${urlArray[2]}/${urlArray[3]}`,
            ]);
          }

          this.previousSystem = this.selectedSystem;
          if (notifyService) {
            this.sharedData["selectedSystem"] = this.selectedSystem;
            this.fetchSystems(this.selectedSystem)
              .then((options) => {
                this.sharedData["vpars"] = options;
                this.sharedServices.changeData(this.sharedData);
                return true;
              })
              .catch((error) => {
                console.error("Error fetching systems", error);
                this._router.navigateByUrl("/welcome");
                new AppComponent(this._snackBar).showSnackBar(
                  "Error in Fetching Systems",
                  "error"
                );
                return false;
              });
          }
        });
    }
  }

  fetchSystems(selectedSystem: string) {
    const params = {
      url: `vpars/fetchSystems`,
      system: selectedSystem,
    };

    return new Promise((resolve, reject) => {
      this.httpAdapter.httpGetNew(params).subscribe(
        (res) => {
          const options = res.body || [];
          resolve(options);
        },
        (error) => {
          console.error("Error:", error);
          alert("An error occurred while fetching systems");
          reject(error);
        }
      );
    });
  }

  onSystemChange() {
    const payload = this.userService.getPayload();
    console.log(payload["modules"], payload);
    if (this.previousSystem !== this.selectedSystem) {
      // console.log("System Change", this.currentRouteUrl, this.selectedSystem);
      // if (this.currentRouteUrl == "") {
      //   console.log("Sai", this.menuItems[0]["path"]);
      //   this.currentRouteUrl = this.selectedSystem + "/scripts";
      // }
      this.handleNext(
        `You are Navigating to *** ${this.selectedSystem}`,
        true,
        true,
        this.currentRouteUrl
      );
    }
    this.previousSystem = this.selectedSystem;
  }

  logout() {
    this.sharedServices.changeData({});
    this.userService.logout();
  }

  ngOnDestroy(): void {
    this.Sharedsubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
    this.socketSubscriptionForWelcome.unsubscribe();
  }
}
