<form class="general" [formGroup]="GeneralForm">
  <div class="form-group row">
    <!--USER-->
    <label class="col-2 col-form-label" *ngIf="this.GeneralForm.get('USER')"
      >USER
      <div *ngIf="newKeys.includes('USER')">
        <span class="text-center notes"
          ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
          field</span
        >
      </div>
    </label>
    <div class="col-4" *ngIf="this.GeneralForm.get('USER')">
      <input
        readonly
        type="text"
        class="form-control"
        formControlName="USER"
        placeholder="USER"
      />
    </div>
    <!-- <label class="col-1 col-form-label">REGVOLT</label>
        <div class="col-2" style="margin: 6px;">
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="REGVOLT" value="RTST">
                <label class="form-check-label">RTST</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="REGVOLT" value="VTST">
                <label class="form-check-label">VTST</label>
            </div>
        </div> -->

    <!--RUNMODE-->
    <label class="col-2 col-form-label" *ngIf="this.GeneralForm.get('RUNMODE')"
      >RUNMODE
      <div *ngIf="newKeys.includes('RUNMODE')">
        <span class="text-center notes"
          ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
          field</span
        >
      </div>
    </label>
    <div
      class="col-3"
      style="margin: 6px; display: flex"
      *ngIf="this.GeneralForm.get('RUNMODE')"
    >
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          formControlName="RUNMODE"
          value="PARALLEL"
        />
        <label class="form-check-label">PARALLEL</label>
      </div>

      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          formControlName="RUNMODE"
          value="SEQUENTIAL"
        />
        <label class="form-check-label">SEQUENTIAL</label>
      </div>
    </div>
  </div>

  <!--RUNSTATUS @jira PM-878 -->
  <!-- <div class="form-group row" *ngIf="this.GeneralForm.get('RUNSTATUS')">
    <label class="col-2 col-form-label"
      >RUNSTATUS
      <div *ngIf="newKeys.includes('RUNSTATUS')">
        <span class="text-center notes"
          ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
          field</span
        >
      </div>
    </label>
    <div class="col-4">
      <input
        type="text"
        readonly
        class="form-control"
        formControlName="RUNSTATUS"
        placeholder="RUNSTATUS"
      />
    </div>
    <label class="col-2 col-form-label">RUNID </label>
    <div class="col-4">
      <input
        readonly
        type="text"
        value="{{ runId.value | filterform }}"
        placeholder="RUNID"
        class="form-control"
      />
    </div>
  </div> -->

  <!--LOGTYPE @jira PM-878 -->
  <!-- <div class="form-group row" *ngIf="this.GeneralForm.get('LOGTYPE')">
    <label class="col-2 col-form-label"
      >LOGTYPE
      <div *ngIf="newKeys.includes('LOGTYPE')">
        <span class="text-center notes"
          ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
          field</span
        >
      </div>
    </label>
    <div class="col-4">
      <select class="form-control" formControlName="LOGTYPE">
        <option value="TRANSACTION">TRANSACTION</option>
        <option value="RUN">RUN</option>
        <option value="BOTH">BOTH</option>
      </select>
    </div>
  </div> -->

  <div class="form-group row">
    <!-- SUMMARY REPORT -->
    <div class="col-6" style="display: flex; align-items: center">
      <label class="col-5 p-0 col-form-label"
        >SUMMARY REPORT
        <div
          *ngIf="
            newKeys.includes('PERF_SUMM_ALL') ||
            newKeys.includes('PERF_SUMM_MAC') ||
            newKeys.includes('PERF_SUMM_PGM') ||
            newKeys.includes('PERF_SUMM_FIL') ||
            newKeys.includes('PERF_SUMM_ECB')
          "
        >
          <span class="text-center notes"
            ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
            field</span
          >
        </div>
      </label>
      <div class="col-7" style="display: flex">
        <!--PERF_SUMM_ALL-->
        <div
          class="form-check form-check-inline"
          *ngIf="this.GeneralForm.get('PERF_SUMM_ALL')"
        >
          <input
            class="form-check-input"
            formControlName="PERF_SUMM_ALL"
            (change)="onSummaryChange($event)"
            type="checkbox"
          />
          <label class="form-check-label">ALL</label>
        </div>

        <!--PERF_SUMM_ALL-->
        <div
          class="form-check form-check-inline"
          *ngIf="
            !GeneralForm.value['PERF_SUMM_ALL'] &&
            this.GeneralForm.get('PERF_SUMM_MAC')
          "
        >
          <input
            class="form-check-input"
            formControlName="PERF_SUMM_MAC"
            type="checkbox"
          />
          <label class="form-check-label">MAC</label>
        </div>

        <!--PERF_SUMM_ALL-->
        <div
          class="form-check form-check-inline"
          *ngIf="
            !GeneralForm.value['PERF_SUMM_ALL'] &&
            this.GeneralForm.get('PERF_SUMM_PGM')
          "
        >
          <input
            class="form-check-input"
            formControlName="PERF_SUMM_PGM"
            type="checkbox"
          />
          <label class="form-check-label">PGM</label>
        </div>
        <div
          class="form-check form-check-inline"
          *ngIf="
            !GeneralForm.value['PERF_SUMM_ALL'] &&
            this.GeneralForm.get('PERF_SUMM_FIL')
          "
        >
          <input
            class="form-check-input"
            formControlName="PERF_SUMM_FIL"
            type="checkbox"
          />
          <label class="form-check-label">FILE</label>
        </div>
        <div
          class="form-check form-check-inline"
          *ngIf="
            !GeneralForm.value['PERF_SUMM_ALL'] &&
            this.GeneralForm.get('PERF_SUMM_ECB')
          "
        >
          <input
            class="form-check-input"
            formControlName="PERF_SUMM_ECB"
            type="checkbox"
          />
          <label class="form-check-label">ECB</label>
        </div>
      </div>
    </div>

    <!--SESSIONWAIT-->
    <label
      class="col-2 col-form-label"
      *ngIf="this.GeneralForm.get('SESSIONWAIT')"
      >SESSIONWAIT
      <div *ngIf="newKeys.includes('SESSIONWAIT')">
        <span class="text-center notes"
          ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
          field</span
        >
      </div>
    </label>
    <div class="col-4" *ngIf="this.GeneralForm.get('SESSIONWAIT')">
      <input
        type="number"
        formControlName="SESSIONWAIT"
        class="form-control"
        placeholder="Time in ms"
      />
    </div>
    <br />
    <br />
    <br />
    <!--CMD_TRM_ADDR-->
    <label
      class="col-2 col-form-label"
      *ngIf="this.GeneralForm.get('CMD_TRM_ADDR')"
      >CMD TRM ADDR
      <div *ngIf="newKeys.includes('CMD_TRM_ADDR')">
        <span class="text-center notes"
          ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
          field</span
        >
      </div>
    </label>
    <div class="col-4" *ngIf="this.GeneralForm.get('CMD_TRM_ADDR')">
      <input
        type="text"
        formControlName="CMD_TRM_ADDR"
        class="form-control"
        placeholder="Command Terminal Lniata"
      />
    </div>

    <!--DO SIGNON @jira PM-878-->

    <!-- <div
      class="col-2 checkboxView form-check form-check-inline"
      *ngIf="this.GeneralForm.get('SIGNON')"
    >
      <label class="form-check-label">DO SIGNON</label> &nbsp;&nbsp;
      <input
        class="form-check-input"
        formControlName="SIGNON"
        type="checkbox"
      />
      <div *ngIf="newKeys.includes('SIGNON')">
        <span class="text-center notes"
          ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
          field</span
        >
      </div>
    </div> -->

    <!--DOLOAD-->
    <div
      class="col-3 checkboxView form-check form-check-inline"
      *ngIf="this.GeneralForm.get('DOLOAD')"
    >
      <label class="form-check-label">DO LOAD</label> &nbsp;&nbsp;
      <input
        class="form-check-input"
        formControlName="DOLOAD"
        type="checkbox"
      />
      <div *ngIf="newKeys.includes('DOLOAD')">
        <span class="text-center notes"
          ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
          field</span
        >
      </div>
    </div>
    <br />
    <div class="col-3"></div>

    <!--RUNPH1-->
    <div
      class="col-3 form-check form-check-inline"
      *ngIf="this.GeneralForm.get('RUNPH1')"
    >
      <label class="col-form-label">RUN PHASE 1</label> &nbsp;&nbsp;
      <input
        class="form-check-input"
        formControlName="RUNPH1"
        type="checkbox"
      />
      <div *ngIf="newKeys.includes('RUNPH1')">
        <span class="text-center notes"
          ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
          field</span
        >
      </div>
    </div>

    <!--RUNPH2-->
    <div
      class="col-3 form-check form-check-inline"
      *ngIf="this.GeneralForm.get('RUNPH2')"
      style="margin-left: 6px"
    >
      <label class="col-form-label">RUN PHASE 2</label> &nbsp;&nbsp;
      <input
        class="form-check-input"
        formControlName="RUNPH2"
        type="checkbox"
      />
      <div *ngIf="newKeys.includes('RUNPH2')">
        <span class="text-center notes"
          ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
          field</span
        >
      </div>
    </div>
  </div>

  <!--SEND_EMAIL-->
  <div
    class="form-group row form-check"
    *ngIf="this.GeneralForm.get('SEND_EMAIL')"
  >
    <label class="col-2 col-form-label"
      >SEND EMAIL
      <div *ngIf="newKeys.includes('SEND_EMAIL')">
        <span class="text-center notes"
          ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
          field</span
        >
      </div>
    </label>
    <div class="col-4">
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          formControlName="SEND_EMAIL"
          type="checkbox"
        />
      </div>
    </div>
    <label class="col-2 col-form-label" *ngIf="GeneralForm.value['SEND_EMAIL']"
      >USER_EMAIL</label
    >
    <div class="col-4" *ngIf="GeneralForm.value['SEND_EMAIL']">
      <input
        type="text"
        formControlName="USER_EMAIL"
        class="form-control"
        placeholder="User's Email"
      />
      <span class="span-text"
        >Specify list of Email addresses seperated by Semi Colon ;</span
      >
    </div>
  </div>
</form>
<!-- {{GeneralForm.value|json}} -->
