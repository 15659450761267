<form [formGroup]="conf">
  <div class="form-group row">
    <!-- TRM ADDRS -->
    <label class="col-2 col-form-label" *ngIf="this.conf.get('TRM_ADDR')"
      >TRM ADDRS
      <div *ngIf="newKeys.includes('TRM_ADDR')">
        <span class="text-center notes"
          ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
          field</span
        >
      </div>
    </label>
    <div class="col-4" *ngIf="this.conf.get('TRM_ADDR')">
      <input
        type="text"
        class="form-control"
        formControlName="TRM_ADDR"
        placeholder="Terminal Address"
      />
    </div>

    <!-- LOOP_IT -->
    <label class="col-2 col-form-label" *ngIf="this.conf.get('LOOP_IT')"
      >LOOP_IT
      <div *ngIf="newKeys.includes('LOOP_IT')">
        <span class="text-center notes"
          ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
          field</span
        >
      </div>
    </label>
    <div class="col-4" *ngIf="this.conf.get('LOOP_IT')">
      <input
        disabled
        type="number"
        class="form-control"
        formControlName="LOOP_IT"
        placeholder="Loop Scripts"
        oninput="this.value = Math.abs(this.value)"
      />
      <span class="span-text"
        >Specify number of times the Input Script/s have to be executed ;</span
      >
    </div>
  </div>

  <div class="form-group row">
    <!-- System Date -->
    <label class="col-2 col-form-label" *ngIf="this.conf.get('SYS_DATE')"
      >SYSTEM DATE
      <div *ngIf="newKeys.includes('SYS_DATE')">
        <span class="text-center notes"
          ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
          field</span
        >
      </div>
    </label>
    <div class="col-4" *ngIf="this.conf.get('SYS_DATE')">
      <input
        type="date"
        class="form-control"
        formControlName="SYS_DATE"
        placeholder="System Date"
      />
    </div>
  </div>

  <!-- Add Script -->
  <div class="row m-1">
    <button
      class="m-1 add-script"
      (click)="addScript()"
      style="padding: auto; border-radius: 8px"
    >
      <span style="font-size: calc(0.5vw + 0.3vh + 0.2vmin)">Add Script</span
      ><span style="font-size: calc(0.8vw + 0.3vh + 0.2vmin)"> &nbsp;+</span>
    </button>
  </div>
  <div class="row m-1" *ngIf="MSG_FILES.controls.length > 0">
    <span class="rounded border border-primary col" style="width: 100%">
      <label
        class="col font-weight-bold display-5 text-left"
        style="
          color: grey;
          font-size: calc(0.5vw + 0.4vh + 0.4vmin);
          padding-left: 0px;
        "
        >FILES</label
      >
      <div formArrayName="MSG_FILES">
        <div
          class="form-group row"
          [formGroupName]="i"
          *ngFor="let inputfile of MSG_FILES.controls; let i = index"
        >
          <label class="col-2 col-form-label text-center">View FILE</label>
          <div class="col-8" style="margin-top: 1%">
            <a class="fileNameView" (click)="showInput(inputfile)">
              <!-- <img src="assets/Images/File_icon.png" style="width: 40px;height: 35px;"><br> -->
              {{ inputfile.value.NAME }}.input &nbsp; (Click to view file)
            </a>
          </div>

          <div class="col-2 text-center">
            <button
              mat-flat-button
              color="warn"
              class="scriptDelete"
              (click)="removeScript(i)"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </span>
  </div>

  <!-- Add iRTF File -->
  <div class="row m-1">
    <button
      class="m-1 add-script"
      (click)="addiRTF()"
      style="padding: auto; border-radius: 8px"
    >
      <span style="font-size: calc(0.5vw + 0.3vh + 0.2vmin)">Add iRTF File</span
      ><span style="font-size: calc(0.8vw + 0.3vh + 0.2vmin)"> &nbsp;+</span>
    </button>
  </div>

  <div class="row m-1" *ngIf="IRTF_FILES.controls.length > 0">
    <span class="rounded border border-primary col" style="width: 100%">
      <label
        class="col font-weight-bold display-5 text-left"
        style="
          color: grey;
          font-size: calc(0.5vw + 0.4vh + 0.4vmin);
          padding-left: 0px;
        "
        >IRTF FILES</label
      >
      <div formArrayName="IRTF_FILES">
        <div
          class="form-group row"
          [formGroupName]="i"
          *ngFor="let irtfFile of IRTF_FILES.controls; let i = index"
        >
          <label class="col-2 col-form-label text-center">View FILE</label>
          <div class="col-8" style="margin-top: 1%">
            <a
              class="fileNameView"
              (click)="showIrtfFile(irtfFile.value.inputFileRef)"
            >
              {{ irtfFile.value.NAME }}.irtf &nbsp; (Click to view IRTF file)
            </a>
          </div>

          <div class="col-2 text-center">
            <button
              mat-flat-button
              color="warn"
              class="scriptDelete"
              (click)="removeiRTF(i)"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </span>
  </div>
</form>

<!--
<div class="row" style="margin-left: 0px;">
    <button mat-flat-button color="primary" (click)="addScript()">Add Script</button>
    <div formArrayName="MSG_FILES">
        <div class="form-group row" [formGroupName]="i" *ngFor="let inputfile of MSG_FILES.controls;let i = index;">

            <label class="col-2 col-form-label">FILE NAME</label>
            <div class="col-4">
                <input [readonly]="true" type="text" class="form-control" formControlName="NAME" placeholder="FILE NAME">
            </div>
            <label class="col-2 col-form-label">View FILE</label>
            <div class="col-3" style="margin-top: 1%;">
                <a class="fileNameView" (click)="showInput(inputfile)">{{inputfile.value.NAME}}.input &nbsp;(click
                    to
                    view file)</a>
            </div>

            <label class="col-1 col-form-label">TYPE</label>
            <div class="col-3" style="margin: 6px;">

                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" formControlName="TYPE" value="ALC">
                    <label class="form-check-label">ALC</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" formControlName="TYPE" value="LOC">
                    <label class="form-check-label">LOC</label>
                </div>

            </div>


            <div class="col-2" ><button mat-flat-button color="warn" class="scriptDelete"
                    (click)="removeScript(i)">Delete</button>
            </div>

        </div>
    </div>
</div> -->
<!-- <div> -->

<!-- {{conf.value.MSG_FILES|json}} -->
<!-- </div> -->
<!-- <form class="general" [formGroup]="conf">
    <button mat-flat-button color="primary" (click)="addScript()">Add Input</button>
    <div class="form-group row" formArrayName="MSG_FILES" *ngFor="let inputFile of inpFileData;let i=index;">
        <fieldset class="scheduler-border col-10">
            <legend class="scheduler-border">Input 1</legend>
            <div class="control-group">
                <div class=" row">
                    <label class="col-2 col-form-label">FILE NAME</label>
                    <div class="col-3">
                        <input type="text" class="form-control" [formControl]="MSG_FILES[i]['NAME']" placeholder="FILE NAME">
                    </div>

                    <label class="col-3 col-form-label">TERMINAL TYPE</label>
                    <div class="col-2" style="margin: 6px;">

                        <div class="form-check form-check-inline" >
                            <input class="form-check-input" type="radio" [formControl]="MSG_FILES[i]['NAME']" formControlName="TYPE" value="ALC">
                            <label class="form-check-label">ALC</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" [formControl]="MSG_FILES[i]['NAME']" formControlName="TYPE" value="LOC">
                            <label class="form-check-label">LOC</label>
                        </div>

                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-1 col-form-label">PATH</label>
                    <div class="col-5">
                        <input type="text" class="form-control" [formControl]="MSG_FILES[i]['PATH']" placeholder="PATH">
                    </div>


                </div>
            </div>
        </fieldset>
    </div>


</form> -->
