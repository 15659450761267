<form [formGroup]="PhasesForm">
  <!--PHASE 1-->
  <div
    class="row"
    *ngIf="this.PhasesForm.get('PHASE1') || this.PhasesForm.get('PHASE2')"
  >
    <div
      *ngIf="this.PhasesForm.get('PHASE1')"
      style="display: flex; align-items: center"
      class="form-group col-md-6"
      formGroupName="PHASE1"
    >
      <label class="col-5 col-form-label"
        >PHASE 1: VPARS
        <div *ngIf="newKeys.includes('PHASE1')">
          <span class="text-center notes"
            ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
            field</span
          >
        </div>
      </label>
      <div
        class="col-7"
        *ngIf="this.PhasesForm.get('PHASE1').get('VPARS_NAME')"
      >
        <div *ngIf="!phase1?.valid">
          <div class="text-danger" [hidden]="!phase1.errors.required">
            <p>Phase1 is required</p>
          </div>
          <div class="text-danger" [hidden]="!phase1.errors.state">
            <p>Select an option from the dropdown</p>
          </div>
        </div>
        <mat-form-field>
          <input
            #autoCompleteInput1
            type="text"
            placeholder="Select VPARS"
            aria-label="Number"
            matInput
            formControlName="VPARS_NAME"
            [matAutocomplete]="auto1"
          />
          <mat-autocomplete #auto1="matAutocomplete">
            <mat-option
              *ngFor="let option of filteredOptions1 | async"
              [value]="option"
            >
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div
      *ngIf="this.PhasesForm.get('PHASE2')"
      style="display: flex; align-items: center"
      class="col-md-6 form-group"
      formGroupName="PHASE2"
    >
      <label class="col-5 col-form-label"
        >PHASE 2: VPARS
        <div *ngIf="newKeys.includes('PHASE2')">
          <span class="text-center notes"
            ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
            field</span
          >
        </div>
      </label>
      <div
        class="col-7"
        *ngIf="this.PhasesForm.get('PHASE2').get('VPARS_NAME')"
      >
        <div *ngIf="!phase2?.valid">
          <div class="text-danger" [hidden]="!phase2.errors.required">
            <p>Phase2 is required</p>
          </div>
          <div class="text-danger" [hidden]="!phase2.errors.state">
            <p>Select an option from the dropdown</p>
          </div>
        </div>
        <mat-form-field>
          <input
            #autoCompleteInput2
            type="text"
            placeholder="Select VPARS"
            aria-label="Number"
            matInput
            formControlName="VPARS_NAME"
            [matAutocomplete]="auto2"
          />
          <mat-autocomplete #auto2="matAutocomplete">
            <mat-option
              style="font-size: 14px"
              *ngFor="let option of filteredOptions2 | async"
              [value]="option"
            >
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <!-- <label
      class="col-2 col-form-label"
      *ngIf="this.PhasesForm.get('PHASE1').get('VPARS_TYPE')"
    >
      VPARS_TYPE
    </label>
    <div class="col-3" *ngIf="this.PhasesForm.get('PHASE1').get('VPARS_TYPE')">
      <select class="form-control" formControlName="VPARS_TYPE">
        <option value="SHARED">SHARED</option>
        <option value="POOL">POOL</option>
      </select>
    </div> -->
  </div>

  <!-- PHASE 2 @jira PM-878  -->
  <!-- <div
    class="form-group row"
    formGroupName="PHASE2"
    *ngIf="this.PhasesForm.get('PHASE2')"
  >
    <label class="col-3 col-form-label"
      >PHASE 2: VPARS
      <div *ngIf="newKeys.includes('PHASE2')">
        <span class="text-center notes"
          ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
          field</span
        >
      </div>
    </label>
    <div class="col-4" *ngIf="this.PhasesForm.get('PHASE2').get('VPARS_NAME')">
      <div *ngIf="!phase2?.valid">
        <div class="text-danger" [hidden]="!phase2.errors.required">
          <p>Phase2 is required</p>
        </div>
        <div class="text-danger" [hidden]="!phase2.errors.state">
          <p>Select an option from the dropdown</p>
        </div>
      </div>
      <mat-form-field>
        <input
          #autoCompleteInput2
          type="text"
          placeholder="Select VPARS"
          aria-label="Number"
          matInput
          formControlName="VPARS_NAME"
          [matAutocomplete]="auto2"
        />
        <mat-autocomplete #auto2="matAutocomplete">
          <mat-option
            style="font-size: 14px"
            *ngFor="let option of filteredOptions2 | async"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <label
      class="col-2 col-form-label"
      *ngIf="this.PhasesForm.get('PHASE2').get('VPARS_TYPE')"
    >
      VPARS_TYPE
    </label>
    <div class="col-3" *ngIf="this.PhasesForm.get('PHASE2').get('VPARS_TYPE')">
      <select class="form-control" formControlName="VPARS_TYPE">
        <option value="SHARED">SHARED</option>
        <option value="POOL">POOL</option>
      </select>
    </div>
  </div> -->
</form>
