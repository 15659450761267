<div
  [ngClass]="{
    phaseOne: true
  }"
>
  <div class="phaseContainer" [ngClass]="{}">
    <div class="phaseData" #phaseData>
      <p
        class="m-1 text-center text-dark transacText"
        *ngIf="aciveSubScript"
        style="
          position: sticky;
          top: 30px;
          z-index: 9;
          background-color: white;
          color: black;
          font-weight: bold;
        "
      >
        ***{{ aciveSubScript }}
      </p>
      <ng-container
        *ngFor="
          let item of PHData;
          let index = index;
          let last = last;
          trackBy: trackByFn
        "
      >
        <p
          class="m-1 p-1 text-center text-dark transacText"
          style="
            position: sticky;
            top: 0px;
            z-index: 10;
            background-color: white;
            color: black;
            font-weight: bold;
          "
          *ngIf="filteredScript && index == 0"
        >
          SCRIPT : &nbsp;{{ filteredScript }}
        </p>
        <p
          class="m-1 p-1 text-center text-dark transacText"
          style="
            position: sticky;
            top: 0px;
            z-index: 10;
            background-color: white;
            color: black;
            font-weight: bold;
          "
          *ngIf="
            !filteredScript && item.firstFileNameOccurrence && item.FileName
          "
        >
          SCRIPT : &nbsp;{{ item.FileName }}
        </p>

        <p
          class="m-1 text-center text-dark transacText"
          *ngIf="
            item.firstSubScriptNameOccurrence &&
            item.subScript != 'No Subscript'
          "
          subscripthiding
          (reachedBottom)="loadSubScript(item.subScript)"
          style="
            /* position: sticky; */
            top: 30px;
            z-index: 9;
            background-color: white;
            color: black;
            font-weight: bold;
          "
        >
          ***{{ item.subScript }}
        </p>
        <div
          appLazyload
          (reachedBottom)="loadMoreData(index + 1)"
          class="card mt-1"
          style="
            background-color: white;
            border: 1px black solid;
            border-radius: 10px;
            overflow: hidden;
          "
        >
          <div class="card-header p-1 input">
            <div style="color: black; display: flex; flex-direction: column">
              <div
                style="display: flex; justify-content: flex-end; height: 20px"
              >
                <button
                  data-toggle="tooltip"
                  data-placement="top"
                  title="View Trace Data"
                  class="btn btn-sm btn-none p-0"
                  style="height: 20px"
                  (click)="moveToTrace(item['trnsId'])"
                >
                  <mat-icon
                    style="
                      color: black;
                      font-size: 16px;
                      height: 15px;
                      width: 15px;
                    "
                    >data_usage</mat-icon
                  ></button
                >&nbsp;&nbsp;&nbsp;&nbsp;
                <span
                  class="mr-3"
                  style="color: black; font-size: 12px; font-weight: bold"
                  >[ {{ item["trnsId"] | hex }} ]</span
                >
              </div>
              <div class="inputPre">
                <pre class="m-0" style="color: black">[ {{ item["MI"] }} ]</pre>
              </div>
            </div>
          </div>
          <div
            class="card-body p-2"
            style="
              cursor: default;
              max-height: 16vh;
              min-height: 16vh;
              overflow: auto;
            "
            [id]="
              (phase == 'PH2' ? 'executionph2' : 'executionph1') +
              item['trnsId']
            "
            (scroll)="
              onScroll(
                (phase == 'PH1' ? 'executionph2' : 'executionph1') +
                  item['trnsId'],
                $event
              )
            "
          >
            <div
              class="container"
              [ngClass]="{
                output: item['MO'],
                dump: !item['MO']
              }"
            >
              <div class="row" *ngIf="item['MO']">
                <div class="outputPre">
                  <pre
                    style="
                      color: black;
                      text-align: inherit;
                      font-size: 0.85vw;
                      width: 100%;
                      white-space: pre;
                      margin-bottom: 0px;
                    "
                    >{{ item["MO"] }}
                </pre
                  >
                </div>
              </div>
              <ng-container *ngIf="item['AB']">
                <div class="container p-0">
                  <div class="row">
                    <div class="col p-0" style="background-color: white">
                      <div class="text-dark p-1" style="font-weight: bold">
                        [DUMP]
                      </div>
                      <div class="error-container">
                        <table class="table dumpTable">
                          <thead>
                            <tr style="background-color: #324354">
                              <th>S.No</th>
                              <th>ERROR</th>
                              <th>SO</th>
                              <th>OBJ</th>
                              <th>DISP</th>
                              <th>FUNCTION</th>
                              <th>LOADSET</th>
                            </tr>
                          </thead>
                          <!-- {{transacData['AB']|json}} -->
                          <tbody>
                            <ng-container
                              *ngIf="item['AB'] && item['AB'].length"
                            >
                              <ng-container
                                *ngFor="
                                  let dumptItem of item['AB'];
                                  let i = index
                                "
                              >
                                <tr style="width: 100%">
                                  <!-- *ngFor="let dumpData of transacData['AB']" -->
                                  <td>{{ i + 1 }}</td>
                                  <td>
                                    {{
                                      dumptItem["ABrecordTwo"]["errorType"]
                                    }}-{{
                                      dumptItem["ABrecordTwo"]["errorPrefix"]
                                    }}{{
                                      dumptItem["ABrecordTwo"]["errorCode"]
                                    }}
                                  </td>
                                  <td>
                                    {{ dumptItem["ABrecordOne"]["data"]["SO"] }}
                                  </td>
                                  <td>
                                    {{
                                      dumptItem["ABrecordOne"]["data"]["object"]
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      dumptItem["ABrecordOne"]["data"][
                                        "pgmDisp"
                                      ]
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      dumptItem["ABrecordOne"]["data"][
                                        "functionName"
                                      ]
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      dumptItem["ABrecordOne"]["data"][
                                        "loadset"
                                      ]
                                    }}
                                  </td>
                                </tr>
                              </ng-container>
                            </ng-container>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
