import { IrtfDispComponent } from "./irtf-disp/irtf-disp.component";
import { IrtfSelectionComponent } from "./irtf-selection/irtf-selection.component";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
} from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { InputDispComponent } from "./input-disp/input-disp.component";
import {
  FormBuilderConfiguration,
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";

import { MatDialog } from "@angular/material/dialog";
import { InputSelectionComponent } from "./input-selection/input-selection.component";
import { HttpAdapterService } from "src/app/_services/http-adapter.service";
import { HttpClient } from "@angular/common/http";
import { DialogueComponent } from "src/app/errorPages/dialogue/dialogue.component";
import { ShareDataService } from "src/app/_services/share-data.service";

@Component({
  selector: "app-scripts",
  templateUrl: "./scripts.component.html",
  styleUrls: ["./scripts.component.scss"],
})
export class ScriptsComponent implements OnInit, OnDestroy {
  // public _conf;
  @Input() conf;

  @Input() validator;

  public newKeys = [];
  private selectedSystem: string = "";
  subscription: any;
  /**
   * Below function is to collect the newly added forms in an arry
   */
  @Input() public set newKeysAdded(val: string) {
    if (val) {
      this.newKeys = [];
      Object.keys(val).map((value) => {
        this.newKeys.push(value);
      });
    }
  }

  @Output() changedConf = new EventEmitter();
  httpAdapter: HttpAdapterService;
  inputFile = [];
  iRtfFile: [];
  // public scripts = [];

  /** We changed FormBuilder to RxFormBuilder because the parent form is related to the rxform,
   *  so the new script pushing form also wants to be in the rxform (PM-125). */

  constructor(
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    public http: HttpClient,
    private sharedServices: ShareDataService
  ) {
    this.httpAdapter = new HttpAdapterService(this.http);
  }

  ngOnInit() {
    this.socketListener();
  }

  socketListener() {
    this.subscription = this.sharedServices.currentData$.subscribe((data) => {
      if (
        data["selectedSystem"] &&
        data["selectedSystem"] != this.selectedSystem
      )
        this.selectedSystem = data["selectedSystem"];
    });
  }

  showInput(file) {
    var self = this;
    file.value["_id"] = file.value["_ID"];
    var inputFilegetParam = {
      url: "inputFile/viewFile",
      data: file.value,
    };
    self.httpAdapter.httpPost(inputFilegetParam, function (err, fileResp) {
      if (!err) {
        /**
         * Checking if there is any error while viewing the Input Script File
         * If error exists, prompting for an window to show the error
         */
        if (fileResp.body.isError) {
          //console.log('Error Message --->',fileResp.body.message);
          // window.alert(`File doesn't exist, Please delete and add the Script`);
          self.dialog.open(DialogueComponent, {
            direction: "ltr",
            minWidth: "350px",
            position: { top: "10px" },
            panelClass: "my-alert-message-class",
            data: {
              Title: "Error Message",
              Content: `File doesn't exist, Please delete and add the Script`,
            },
          });
          return true;
        }
        /**
         * To display the normal and subscript transaction in the view file, the blow code is used (PD-37)
         */

        var inputFile = fileResp["body"][0]["inputFileContent"].split("\n");

        const dialogRef = self.dialog.open(InputDispComponent, {
          // data: { value: self.inputFile.join('\n') }
          /**
           * Commanded the above line.
           * Rather than sharing as a string, send the array as it. (PM-157)
           */
          data: { value: inputFile, irtf: fileResp["body"][0]["irtfRequired"] },
        });
        dialogRef.afterClosed().subscribe((result) => {
          console.log(`Dialog result: ${result}`);
        });
      }
    });
  }

  showIrtfFile(fileId) {
    var self = this;
    var irtfFilegetParam = {
      url: "irtfile/viewFile",
      data: {
        ObjectId: fileId,
      },
    };
    self.httpAdapter.httpPost(irtfFilegetParam, function (err, fileResp) {
      if (!err) {
        if (fileResp.body.isError) {
          // window.alert(`File doesn't exist, Please delete and add the Script`);
          self.dialog.open(DialogueComponent, {
            direction: "ltr",
            minWidth: "350px",
            position: { top: "10px" },
            data: {
              Title: "Error Message",
              Content: `File doesn't exist, Please delete and add the Script`,
            },
          });
          return true;
        }
        self.iRtfFile = fileResp.body;
        const dialogRef = self.dialog.open(IrtfDispComponent, {
          data: { value: self.iRtfFile },
        });
        dialogRef.afterClosed().subscribe((result) => {
          //console.log(`iRTF Dialog Closed : ${result}`);
        });
      }
    });
  }

  // ngOnChanges(changes) {
  //   // console.log("Script value==>",JSON.stringify(changes.conf.previousValue),JSON.stringify(this._conf))
  //   if (JSON.stringify(changes.conf.previousValue) != JSON.stringify(this._conf)) {
  //     var scr = this._conf["SCRIPT"];
  //     this.scriptsForm.patchValue(scr)
  //   }
  // }

  // onChanges(){
  //   this.scriptsForm.valueChanges
  //   .pipe(
  //     debounceTime(1000),
  //     distinctUntilChanged(),
  //   ).subscribe(changeMade=>{
  //     var msgArray = this._conf["SCRIPT"]["MSG_FILES"];
  //     this._conf["SCRIPT"] = {...this._conf["SCRIPT"],...this.scriptsForm.value}
  //     // console.log(this._conf["SCRIPT"])
  //     this.changedConf.emit(this._conf)
  //   })

  // }

  get MSG_FILES() {
    return this.conf.get("MSG_FILES") as FormArray;
  }
  get MSG_FILE_LENGTH() {
    return this.conf.get("NUM_OF_MSGFILES") as FormControl;
  }

  get IRTF_FILES() {
    return this.conf.get("IRTF_FILES") as FormArray;
  }

  /** The below function is to return the new added script values to the UI..
   *  Reference URL : https://docs.rxweb.io/getting-started (PM-125) */

  createMSG_FILEScript() {
    return this._formBuilder.group({
      _ID: [""],
      NAME: ["", RxwebValidators.maxLength({ value: 7 })],
      PATH: [""],
      TYPE: ["ALC"],
      inputFileRef: [""],
    });
  }

  createIRTF_FILEScript() {
    return this._formBuilder.group({
      NAME: ["", RxwebValidators.maxLength({ value: 7 })],
      inputFileRef: [""],
    });
  }

  // inputLimit = 100;
  // inputOffset = 0;
  // addScript() {
  //   var params = {
  //     system: this.selectedSystem,
  //     url: `inputFile/getInputFilesArray?limit=${this.inputLimit}&offset=${this.inputOffset}&searchText=`,

  //   };
  //   this.httpAdapter.httpGetNew(params).subscribe((response) => {

  //     const addScirptDialogue = this.dialog.open(InputSelectionComponent, {
  //       data: { value: response.body },
  //     });
  //     addScirptDialogue.afterClosed().subscribe((data) => {
  //       // let fa = <FormArray>this.conf.get("MSG_FILES")
  //       if (data) {
  //         let fb = this.createMSG_FILEScript();
  //         fb.setParent(this.MSG_FILES);
  //         // fa.controls.push(fb);
  //         this.MSG_FILES.controls.push(fb);
  //         // this.MSG_FILE_LENGTH.patchValue(this.MSG_FILES.length)
  //         console.log(this.MSG_FILES);
  //         // this.MSG_FILES.setParent()
  //         this.MSG_FILES.at(this.MSG_FILES.length - 1).patchValue({
  //           _ID: data["selected"][0]["_id"],
  //           NAME: data["selected"][0]["fileName"],
  //           inputFileRef: data["selected"][0]["fileName"],
  //           TYPE: "ALC",
  //         });
  //       }
  //     });

  //   });

  //   // this.MSG_FILES.push(this.createMSG_FILEScript())
  // }

  addScript() {
    var params = {
      system: this.selectedSystem,
      url: "inputFile/getInputFileList",
    };
    this.httpAdapter.httpPost(params, (error, response) => {
      if (error) {
        console.log("Error", error);
      } else {
        const addScirptDialogue = this.dialog.open(InputSelectionComponent, {
          data: { value: response.body.data },
        });
        addScirptDialogue.afterClosed().subscribe((data) => {
          // let fa = <FormArray>this.conf.get("MSG_FILES")
          if (data) {
            let fb = this.createMSG_FILEScript();
            fb.setParent(this.MSG_FILES);
            // fa.controls.push(fb);
            this.MSG_FILES.controls.push(fb);
            // this.MSG_FILE_LENGTH.patchValue(this.MSG_FILES.length)
            console.log(this.MSG_FILES);
            // this.MSG_FILES.setParent()
            this.MSG_FILES.at(this.MSG_FILES.length - 1).patchValue({
              _ID: data["selected"][0]["_id"],
              NAME: data["selected"][0]["fileName"],
              inputFileRef: data["selected"][0]["fileName"],
              TYPE: "ALC",
            });
          }
        });
      }
    });

    // this.MSG_FILES.push(this.createMSG_FILEScript())
  }

  addiRTF() {
    const params = {
      system: this.selectedSystem,
      url: "irtfFile/getIrtfFileList",
    };
    this.httpAdapter.httpPost(params, (error, response) => {
      if (error) {
        console.log("Error", error);
      } else {
        const addScirptDialogue = this.dialog.open(IrtfSelectionComponent, {
          data: { value: response.body.data },
        });
        addScirptDialogue.afterClosed().subscribe((data) => {
          if (data) {
            let fb = this.createIRTF_FILEScript();
            fb.setParent(this.IRTF_FILES);
            this.IRTF_FILES.controls.push(fb);
            this.IRTF_FILES.at(this.IRTF_FILES.length - 1).patchValue({
              NAME: data["selected"][0]["irtfFileName"],
              inputFileRef: data["selected"][0]["_id"],
            });
          }
        });
      }
    });

    // this.MSG_FILES.push(this.createMSG_FILEScript())
  }

  removeScript(index) {
    this.MSG_FILES.removeAt(index);
  }

  removeiRTF(index) {
    this.IRTF_FILES.removeAt(index);
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
    this.subscription.unsubscribe();
  }
}
