<form [formGroup]="conf" >

    <div formArrayName="MACRO" >
        <button mat-flat-button color="primary" (click)="addTrcMacro()">Add Macro</button>

        <div class="form-group row" [formGroupName]="i" *ngFor="let macro of MACRO.controls;let i = index;">

                <label class="col-2 col-form-label">MACRO NAME</label>
                <div class="col-4">
                    <input type="text" class="form-control" formControlName="NAME" placeholder="FILE NAME">
                </div>


                <div class="col-3" >

                    <div class="form-check form-check-inline"  >
                        <input class="form-check-input" formControlName="OPTION" type="checkbox">
                        <label class="form-check-label">OPTION</label>
                    </div>

                </div>

                <div class="col-3"><button mat-flat-button color="warn" class="scriptDelete" (click)="removeMacro(i)">Delete</button></div>

        </div>
    </div>
    <div formArrayName="SO" class="addSO">
        <button mat-flat-button color="primary" (click)="addTrcSO()">Add SO</button>

        <div class="form-group row" [formGroupName]="i" *ngFor="let inputfile of SO.controls;let i = index;">

                <label class="col-2 col-form-label">SO NAME</label>
                <div class="col-4">
                    <input type="text" class="form-control" formControlName="NAME" placeholder="FILE NAME">
                </div>


                <div class="col-3" >

                    <div class="form-check form-check-inline"  >
                        <input class="form-check-input" formControlName="OPTION" type="checkbox">
                        <label class="form-check-label">OPTION</label>
                    </div>

                </div>

                <div class="col-3"><button mat-flat-button color="warn" class="scriptDelete" (click)="removeSO(i)">Delete</button></div>

        </div>
    </div>


</form>
