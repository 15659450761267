import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { object } from 'underscore';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {
  @Input() GeneralForm;
  // Getting File Name in Scripts Section from RunView Component(Parent)
  // Assigning the value to the runId
  @Input() runId;
  // @Input() validator;

  /**
   * Below function is to collect the newly added forms in an arry
   */
  public newKeys = [];

  @Input() public set newKeysAdded(val: string) {
    if(val){
       this.newKeys = [];
      Object.keys(val).map(value => {
        this.newKeys.push(value)
      })
    }
  }

  @Output() changedConf = new EventEmitter();
  constructor() {
  }

/**
 * Below is a function to un-select other checkboxes while selecting all options
 * in summary report
 */
  onSummaryChange(event){
    if(event){
      this.GeneralForm.get('PERF_SUMM_MAC').setValue(false);
      this.GeneralForm.get('PERF_SUMM_PGM').setValue(false);
      this.GeneralForm.get('PERF_SUMM_FIL').setValue(false);
      this.GeneralForm.get('PERF_SUMM_ECB').setValue(false);
    }
  }
  ngOnInit() {
    //console.log(this.conf);
    // this.onChanges();
  }

  // ngOnChanges(changes){
  //   if (JSON.stringify(changes.conf.previousValue) != JSON.stringify(this._conf)) {

  //     var Gen = this._conf["GENERAL"]
  //     this.General.patchValue(Gen)
  //   }

  // }

  // onChanges(){
  //   this.General.valueChanges
  //   .pipe(
  //     debounceTime(1000),
  //     distinctUntilChanged(),
  //   ).subscribe(changeMade=>{
  //     this._conf["GENERAL"] = {...this._conf["GENERAL"],...this.General.value}
  //     this.changedConf.emit(this._conf)
  //   })

  // }

}
